/* import __COLOCATED_TEMPLATE__ from './defects.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { tracked } from '@glimmer/tracking';
import ScreenService from 'fabscale-app/services/screen';
import { htmlSafe } from '@ember/template';

interface Args {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicCompareDefects extends Component<Args> {
  @service pdf: PdfService;
  @service screen: ScreenService;

  @tracked currentOffset = 0;

  @tracked displayedDefectsValue = '';
  @tracked displayedNoDefectsValue = '';

  get isMobile() {
    return this.screen.isMobile;
  }

  get formattedFirstCreatedDate() {
    const date = deserializeDate(this.args.firstLabSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get formattedSecondCreatedDate() {
    const date = deserializeDate(this.args.secondLabSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-defects-${DateTime.local().toISODate()}.pdf`;
    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  moveTo(offset: number) {
    this.currentOffset = offset;
  }

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
  }

  defectsPercentage(labSample: LabSample) {
    const numberBeans = labSample.numberOfBeans;

    const defectsCatOne = labSample.numberDefectsCatOne;

    return ((defectsCatOne! / numberBeans!) * 100).toFixed(2);
  }

  noDefectsPercentage(labSample: LabSample) {
    const defectsCatOne = labSample.numberDefectsCatOne;
    const numberBeans = labSample.numberOfBeans;

    return (((numberBeans! - defectsCatOne!) / numberBeans!) * 100).toFixed(2);
  }

  get defectPercentageFirst() {
    const first: any = this.args.firstLabSample;
    return this.calculatePercentage(
      first.numberDefectsCatOne,
      first.numberOfBeans
    );
  }

  get defectPercentageSecond() {
    const second: any = this.args.secondLabSample;
    return this.calculatePercentage(
      second.numberDefectsCatOne,
      second.numberOfBeans
    );
  }

  get noDefectPercentageFirst() {
    return 100 - this.defectPercentageFirst;
  }

  get noDefectPercentageSecond() {
    return 100 - this.defectPercentageSecond;
  }

  calculatePercentage(part: number, total: number): any {
    return total ? ((part / total) * 100).toFixed(2) : 0;
  }

  get noDefectStyleFirst() {
    return htmlSafe(`width: ${this.noDefectPercentageFirst}%`);
  }

  get noDefectStyleSecond() {
    return htmlSafe(`width: ${this.noDefectPercentageSecond}%`);
  }

  get defectStyleFirst() {
    return htmlSafe(`width: ${this.defectPercentageFirst}%`);
  }

  get defectStyleSecond() {
    return htmlSafe(`width: ${this.defectPercentageSecond}%`);
  }
}
