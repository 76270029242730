/* import __COLOCATED_TEMPLATE__ from './size.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { tracked } from '@glimmer/tracking';
import ScreenService from 'fabscale-app/services/screen';

interface Args {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicCompareSize extends Component<Args> {
  @service pdf: PdfService;
  @service screen: ScreenService;
  @tracked currentOffset = 0;
  @tracked displayedLengthValue = '';
  @tracked displayedWidthValue = '';
  @tracked displayedScreenSizeValue = '';

  get isMobile() {
    return this.screen.isMobile;
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-size-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  moveTo(offset: number) {
    this.currentOffset = offset;
  }

  @action
  setDisplayedValue(type: string, value: string) {
    if (type === 'screenSizeMode') this.displayedScreenSizeValue = value;
    else if (type === 'widthAvg') this.displayedWidthValue = value;
    else if (type === 'lengthAvg') this.displayedLengthValue = value;
  }

  @action
  updateDisplayedValuesOnToggle(
    valueKey: string,
    deviationKey?: string,
    index?: number
  ) {
    let displayedValue = '';
    const datasets = this.args.firstLabSample && this.args.secondLabSample;
    if (datasets) {
      const firstSample: any = this.args.firstLabSample;
      const secondSample: any = this.args.secondLabSample;
      if (index === undefined) {
        const value = firstSample[valueKey];
        const deviation = deviationKey ? firstSample[deviationKey] : null;
        const valueSecond = secondSample[valueKey];
        const deviationSecond = deviationKey
          ? secondSample[deviationKey]
          : null;
        const displayedFirst = deviation
          ? `${value} ± ${deviation}`
          : `${value}`;
        const displayedSecond = deviationSecond
          ? `${valueSecond} ± ${deviationSecond}`
          : `${valueSecond}`;
        displayedValue = displayedFirst + ' / ' + displayedSecond;
      } else if (index === 0) {
        const value = firstSample[valueKey];
        const deviation = deviationKey ? firstSample[deviationKey] : null;
        displayedValue = deviation ? `${value} ± ${deviation}` : `${value}`;
      } else if (index === 1) {
        const value = secondSample[valueKey];
        const deviation = deviationKey ? secondSample[deviationKey] : null;
        displayedValue = deviation ? `${value} ± ${deviation}` : `${value}`;
      }
    }
    this.setDisplayedValue(valueKey, displayedValue || '-');
  }

  getChartOptions(valueKey: string, deviationKey: string) {
    this.updateDisplayedValuesOnToggle(valueKey, deviationKey);
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: { legend: { display: false } },
      scales: {
        x: { stacked: true, title: { display: true, text: 'Value' } },
        y: { stacked: true, title: { display: true, text: '% Beans' } },
      },
    };
  }

  get screenSizeChartData() {
    return this.prepareChartData(
      this.args.firstLabSample.screenSizeHist,
      this.args.secondLabSample.screenSizeHist
    );
  }

  get widthChartData() {
    return this.prepareChartData(
      this.args.firstLabSample.widthHist,
      this.args.secondLabSample.widthHist
    );
  }

  get lengthChartData() {
    return this.prepareChartData(
      this.args.firstLabSample.lengthHist,
      this.args.secondLabSample.lengthHist
    );
  }

  get screenSizeChartOptions() {
    return this.getChartOptions('screenSizeMode', '');
  }

  get widthChartOptions() {
    return this.getChartOptions('widthAvg', 'widthDeviation');
  }

  get lengthChartOptions() {
    return this.getChartOptions('lengthAvg', 'lengthDeviation');
  }

  prepareChartData(
    firstSampleData: any[] | undefined,
    secondSampleData: any[] | undefined
  ) {
    const labels = Array.from(
      new Set([
        ...(firstSampleData?.map((bin) => bin.count) || []),
        ...(secondSampleData?.map((bin) => bin.count) || []),
      ])
    );

    return {
      labels,
      datasets: [
        {
          label: `#${this.args.firstLabSample.id}`,
          data: firstSampleData?.map((bin) => bin.value),
          backgroundColor: '#0F7799',
        },
        {
          label: `#${this.args.secondLabSample.id}`,
          data: secondSampleData?.map((bin) => bin.value),
          backgroundColor: '#A0D2F7',
        },
      ],
    };
  }

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
  }

  formatCreatedDate(sample: LabSample) {
    const date = deserializeDate(sample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get formattedFirstCreatedDate() {
    return this.formatCreatedDate(this.args.firstLabSample);
  }

  get formattedSecondCreatedDate() {
    return this.formatCreatedDate(this.args.secondLabSample);
  }
}
