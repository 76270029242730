import { service } from '@ember/service';
import Component from '@glimmer/component';
import { ChartData, ChartOptions } from 'chart.js';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  id: string;
  data: ChartData; // Data for the chart
  options: ChartOptions; // Chart.js configuration options
  colorA: string; // Color for the first dataset
  colorB: string; // Color for the second dataset
  labelA: string; // Label for the first dataset
  labelB: string; // Label for the second dataset
  isProgressBar?: boolean; // Determines if the chart should be a progress bar
  onToggleDataset?: (index: number) => void; // Callback for dataset toggle
}

export default class PageRoastPicCompareChart extends Component<Args> {
  @service enumLabels: EnumLabelsService;

  @tracked
  toggledDatasetIndex: number | undefined;

  get data() {
    return this.args.data;
  }

  get id() {
    return this.args.id;
  }

  get options() {
    return this.args.options;
  }

  get chartSelectorItems() {
    const { labelA, labelB, colorA, colorB } = this.args;

    return [
      {
        label: labelA,
        color: colorA,
      },
      {
        label: labelB,
        color: colorB,
      },
    ];
  }

  @action
  updateToggledDatasetIndex(index: number) {
    this.toggledDatasetIndex = index;
    if (this.args.onToggleDataset) {
      this.args.onToggleDataset(index);
    }
  }
}
